<template>
  <div class="page-main-bg">
    <v-container class="main-container">
      <!-------------------------------------------->
      <!---------------ToolBar------------------>
      <!-------------------------------------------->
      <tool-bar
        v-bind="{
          breadcrumbs,
          title: toolbarTitle
        }"
      />
      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" />

      <!--no text messages-->
      <v-sheet
        v-else-if="!smsMessages.length"
        class="pa-10 d-flex align-center justify-center flex-column bg-primary rounded-15"
        height="60vh"
      >
        <img class="no-data-icon" :src="noDataIcon" loading="lazy" />
        <h3 class="grey--text text--darken-3 my-0">
          لا توجد إشعارات مرسلة إلى الأن
        </h3>
        <h5 class="grey--text text-center">
          ستكون الإشعارات متاحة في هذه القائمة مباشرة بعد عملية الإرسال
        </h5>
      </v-sheet>

      <SmsMessageCard
        v-else
        v-for="message in smsMessages"
        :key="message._id"
        :message="message"
        :innerPage="true"
      />

      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="getSmsMessages" />
      </div>
    </v-container>
  </div>
</template>

<script>
import ToolBar from '@/components/listing/ToolBar'
import loader from '@/components/helper/loader.vue'
import SmsMessageCard from '@/components/messages/SmsMessageCard'
import Pagination from '@/components/helper/pagination.vue'
import { messageService } from '@/services'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    ToolBar,
    loader,
    SmsMessageCard,
    Pagination
  },
  data() {
    return {
      isPageLoading: false,
      noDataIcon: require('@/assets/img/email.png'),
      smsMessages: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    loaderProps() {
      return {
        numberOfLines: 7,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    clientPhoneNumber() {
      return this.$route.query?.phone
    },
    clientName() {
      return this.$route.query?.name
    },
    clientId() {
      return this.$route.query?.clientId
    },
    breadcrumbs() {
      const breadcrumbs = [
        {
          text: `${this.clientName}`
        },
        {
          text: 'إشعارات المطالبات',
          disabled: true
        }
      ]
      return this.isCustomer ? breadcrumbs.slice(1) : breadcrumbs
    },
    toolbarTitle() {
      return this.isCustomer
        ? 'إشعارات المطالبات الخاصة بك'
        : `إشعارات المطالبات الخاصة ب ${this.clientName} `
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async getSmsMessages() {
      // In Dev we send messages only to Eng.Muath phone number
      const query = `user=${this.clientId}&environment=${this.currentEnv._id}&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      try {
        this.isPageLoading = true
        const { data } = await messageService.getClientSmsTexts(query)
        this.smsMessages = data.notificationLogsResult
        this.pagination.count = data.count
        this.pagination.pagesCount = Math.ceil(
          this.pagination.count / this.pagination.pageSize
        )
      } catch {
        this.addNotification({
          text: 'خطأ في استرجاع الرسائل النصية للعميل، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    }
  },
  async created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    if (!this.clientPhoneNumber) {
      this.addNotification({
        text: 'خطأ في استرجاع الرسائل النصية للعميل، يرجى المحاوله مجددا',
        color: 'error'
      })
    } else {
      await this.getSmsMessages()
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data-icon {
  width: 50px;
}
</style>
